html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clear-fix:before, #pre-main-document:before,
.clear-fix:after,
#pre-main-document:after {
  content: " ";
  display: table; }


.clear-fix:after,
#pre-main-document:after {
  clear: both; }

.clear-fix, #pre-main-document {
  zoom: 1; }

table {
  font-size: 0.8em;
  width: 100%; }
  table th, table td {
    padding: 0.7em; }
  table thead {
    background-color: #DDD; }
    table thead tr th {
      text-align: left;
      font-weight: bold;
      border-left: 1px solid #CCC; }
      table thead tr th:first-child {
        border-left: 0px; }
  table tbody tr td, table tbody tr th {
    border-left: 1px solid #CCC;
    border-top: 1px solid #CCC;
    background-color: #FAFAFA; }
    table tbody tr td:first-child, table tbody tr th:first-child {
      border-left: 0px; }
  table tbody tr:nth-child(2n) td, table tbody tr:nth-child(2n) th {
    background-color: #F0F0F0; }

#pre-main-document {
  background-color: #000;
  color: #FFF;
  font-family: Arial;
  font-size: 0.75em;
  line-height: 2em; }
  #pre-main-document .left {
    float: left;
    margin-left: 10px; }
  #pre-main-document .right {
    float: right;
    margin-right: 10px; }
  #pre-main-document a {
    text-decoration: none;
    color: #FFF; }
    #pre-main-document a::before {
      color: #FFF;
      content: '['; }
    #pre-main-document a::after {
      color: #EEE;
      content: ']'; }
  @media (min-width: 480px) {
    #pre-main-document {
      position: fixed;
      top: 0;
      left: 0;
      right: 0; } }

#main-document {
  font-family: Arial;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px; }
  #main-document #ukapu-logo {
    padding-top: 20px; }
    @media (min-width: 480px) {
      #main-document #ukapu-logo {
        padding-top: 100px; } }
    #main-document #ukapu-logo .logo {
      height: 75px;
      width: 100%;
      background-image: url("https://www.ukapu.org.uk/ukapu-logo.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    #main-document #ukapu-logo h1 {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 20px; }
  #main-document #query-search form {
    margin-top: 50px;
    margin-bottom: 50px; }
    #main-document #query-search form input[name=identifier] {
      margin-top: 35px;
      width: 100%;
      font-size: 25px;
      line-height: 35px;
      text-align: center;
      border: solid 1px #CCC;
      display: block; }
    #main-document #query-search form input[type=submit] {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 35px;
      height: 35px;
      width: 110px;
      background-color: #DDD;
      border: solid 1px #CCC; }
  #main-document #query-search blockquote {
    font-size: 13px;
    margin-top: 25px;
    margin-bottom: 25px;
    border-top: 1px solid #CCC;
    padding: 20px;
    padding-top: 50px; }
    #main-document #query-search blockquote img {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  #main-document #query-result {
    padding-top: 25px;
    padding-bottom: 25px; }
    @media (min-width: 480px) {
      #main-document #query-result {
        padding-top: 50px; } }
    #main-document #query-result .result-wrapper {
      background-color: #CCC;
      width: 20em;
      height: 20em;
      border: 2px solid #000;
      border-radius: 20em;
      text-align: center;
      margin-left: auto;
      margin-right: auto; }
      #main-document #query-result .result-wrapper > .fas {
        line-height: 1.33333;
        font-size: 15em; }
      #main-document #query-result .result-wrapper.success {
        border: 2px solid #34a434;
        background-color: #46c546; }
        #main-document #query-result .result-wrapper.success > .fas {
          color: #FFF; }
      #main-document #query-result .result-wrapper.failure {
        border: 2px solid #ac2626;
        background-color: #d23333; }
        #main-document #query-result .result-wrapper.failure > .fas {
          color: #FFF; }
    #main-document #query-result .result-level {
      padding-top: 15px; }
      #main-document #query-result .result-level > div {
        border: 2px solid #d6d6d6;
        background-color: #EFEFEF;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 3% 5%;
        font-variant: small-caps;
        font-weight: bold;
        letter-spacing: 0.3em; }
        #main-document #query-result .result-level > div.bronze {
          border: 2px solid #a46628;
          background-color: #CD7F32;
          color: white; }
        #main-document #query-result .result-level > div.silver {
          border: 2px solid #a7a7a7;
          background-color: #C0C0C0;
          color: white; }
        #main-document #query-result .result-level > div.gold {
          border: 2px solid #ccac00;
          background-color: #FFD700;
          color: white; }
    #main-document #query-result .result-details {
      padding-top: 50px; }
      #main-document #query-result .result-details .result-membership-number {
        background-color: #DDD;
        border-radius: 8px;
        border: 1px solid #BBB;
        letter-spacing: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 6px;
        padding-right: 1px; }
    #main-document #query-result .result-details-extra {
      border-top: 1px solid #CCC;
      margin-top: 25px;
      padding-top: 25px; }

strong {
  font-weight: bold; }

p {
  line-height: 1.2em;
  margin-bottom: 2em; }

p:last-child {
  margin-bottom: 0em; }
